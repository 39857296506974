import { t } from '@zupr/i18n'
import classNames from 'classnames'
import React from 'react'

import Question from '../../../../svg/question.svg'

import Tooltip from '../../components/tooltip'

import { Product } from '@zupr/types/shared'
import '../../../../scss/react/product/data.scss'

export interface Spec {
    value: string
    label: string
    help?: string
    className?: string
}

interface SpecsProps {
    specs: Spec[]
}

const Specs = ({ specs }: SpecsProps) => {
    if (!specs?.length) return null
    return (
        <div className="product-data-grid">
            {specs.map(({ value, label, help, className }) => (
                <React.Fragment key={label}>
                    <div className={classNames(className)}>
                        <strong>
                            {t(label)}
                            {!!help && (
                                <Tooltip label={help}>
                                    <Question />
                                </Tooltip>
                            )}
                        </strong>
                    </div>
                    <div>
                        <p>{value}</p>
                    </div>
                </React.Fragment>
            ))}
        </div>
    )
}

interface ProductDataProps {
    specs?: Spec[]
    groups?: {
        title: string
        specs: Spec[]
    }[]
}

const ProductData = ({ specs, groups }: ProductDataProps) => (
    <div className="product-data">
        {!!specs && <Specs specs={specs} />}
        {groups?.map(({ title, specs }) => {
            if (!specs || !specs.length) return null
            return (
                <React.Fragment key={title}>
                    <h5>{t(title)}</h5>
                    <Specs specs={specs} />
                </React.Fragment>
            )
        })}
    </div>
)

export const useCodes = (product: Product) => {
    const specs = []

    if (product.id) {
        specs.push({
            value: product.id,
            label: 'product_location.product.code.zupr_id',
            help: 'product_location.product.code.zupr_id.help',
        })
    }
    if ('isbn' in product && product.isbn) {
        specs.push({
            value: product.isbn,
            label: 'ISBN',
        })
    }
    if ('barcode' in product) {
        specs.push({
            value: product.barcode,
            label: 'product_location.product.code.ean',
        })
    }

    if ('gpc' in product && product.gpc) {
        specs.push({
            value: product.gpc,
            label: 'product_location.product.code.gpc',
            help: 'product_location.product.code.gpc.help',
        })
    }

    if ('item_code' in product && product.item_code) {
        specs.push({
            value: product.item_code,
            label: 'product_location.product.code.item_code',
            help: 'product_location.product.code.item_code.help',
        })
    }

    return specs
}

export default ProductData
