import { ProductLocation } from '@zupr/types/fo'
import { Choice } from '@zupr/types/form'
import { List } from '@zupr/types/generic'
import { Product as SharedProduct } from '@zupr/types/shared'
import isEqual from 'lodash/isEqual'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useCallback, useMemo } from 'react'
import { UrlObject } from 'url'

import Trans from '../../components/trans'

import '../../../../scss/react/product/variations.scss'

export type CreateVariationRoute = (props: { id: string }) => string | UrlObject

type Product = Omit<
    SharedProduct,
    | 'images'
    | 'product_additional_data'
    | 'categories'
    | 'brand'
    | 'product_type'
>

interface VariationRouteProps {
    onVariationRoute: CreateVariationRoute
    onVariation?: never
}
interface VariationCallbackProps {
    onVariation: ({ id }) => void
    onVariationRoute?: never
}

type VariationLinkProps = VariationRouteProps | VariationCallbackProps

type MenuItemProps = VariationRouteProps & {
    choice: Choice
}

const MenuItem = ({ choice, onVariationRoute }: MenuItemProps) => {
    const route = useMemo(() => {
        return onVariationRoute({
            id: choice.value,
        })
    }, [choice.value, onVariationRoute])

    return (
        <Link href={route}>
            <a>{choice.display_name}</a>
        </Link>
    )
}

interface Variation {
    id: Product['id']
    variationFields: Product['variation_fields']
}

type MenuProps = VariationLinkProps & {
    value: Choice['value']
    availableIds: Choice['value'][]
    choices: Choice[]
    name: string
}

const Menu = ({
    value,
    onVariationRoute,
    onVariation,
    availableIds,
    choices,
    name,
}: MenuProps) => {
    const { push } = useRouter()
    const handleSelect = useCallback(
        (e) => {
            const choice = choices.find(
                (variation) => variation.value === e.target.value
            )

            if (!choice) return
            if (onVariationRoute) {
                const route = onVariationRoute({
                    id: choice.value,
                })
                push(route)
            }
            if (onVariation) {
                onVariation({
                    id: choice.value,
                })
            }
        },
        [choices, onVariation, onVariationRoute, push]
    )

    const choice = choices.find((variation) => variation.value === value)

    if (!choice) return null

    if (choices.length <= 1) {
        return <div className="no-variations">{choice.display_name}</div>
    }

    return (
        <>
            <select
                name={name}
                className="small"
                value={value}
                onChange={handleSelect}
            >
                {choices.map(({ value, display_name }) => (
                    <option
                        key={value}
                        value={value}
                        disabled={!availableIds.includes(value)}
                    >
                        {display_name}
                    </option>
                ))}
            </select>
            {onVariationRoute && (
                <noscript>
                    {choices.map((choice) => (
                        <MenuItem
                            key={value}
                            choice={choice}
                            onVariationRoute={onVariationRoute}
                        />
                    ))}
                </noscript>
            )}
        </>
    )
}

type ProductVariationsProps = VariationLinkProps & {
    variationForField: (string) => Variation[]
    noLabels?: boolean
    availableIds: Product['id'][]
    variations: Product[]
    product: Product
}

const ProductVariations = ({
    variationForField,
    noLabels,
    variations,
    product,
    ...props
}: ProductVariationsProps) => (
    <div className="product-variations">
        {Object.keys(product.variation_fields)
            .sort()
            .map((field) => {
                const value = product.id
                const variations = variationForField(field)
                if (variations.length === 1 && !value) return null

                const fieldLabel = !field.includes('.')
                    ? field
                    : field.split('.').pop()

                return (
                    <div key={field} className="product-variations-field">
                        {!noLabels && (
                            <label htmlFor={field} key={`variation.${field}`}>
                                <Trans label={fieldLabel} context="fields" />
                                {`:`}
                            </label>
                        )}
                        <Menu
                            {...props}
                            name={field}
                            value={product.id}
                            choices={variations.map(
                                ({ id, variationFields }) => ({
                                    value: id,
                                    display_name: variationFields[field],
                                })
                            )}
                        />
                    </div>
                )
            })}
        {!Object.keys(product.variation_fields).length && (
            <div className="product-variations-field">
                <label htmlFor="variations">
                    <Trans label="Variaties" />
                    {`:`}
                </label>
                <Menu
                    {...props}
                    name="variations"
                    choices={variations
                        .map(({ id, title, variation_fields }) => ({
                            value: id,
                            display_name: Object.values(variation_fields).length
                                ? Object.values(variation_fields).join(' | ')
                                : `${title} (${id})`,
                        }))
                        .sort((a, b) =>
                            a.display_name.localeCompare(b.display_name)
                        )}
                    value={product.id}
                />
            </div>
        )}
    </div>
)

type ProductVariationsLoaderProps = VariationLinkProps & {
    variations: List<Product>
    availableVariations?: List<ProductLocation>
    noLabels?: boolean
    product: Product
}

const ProductVariationsLoader = ({
    variations,
    availableVariations,
    product,
    ...props
}: ProductVariationsLoaderProps) => {
    const availableIds =
        (availableVariations?.count &&
            availableVariations.results?.map(({ product }) => product.id)) ||
        variations.results.map(({ id }) => id)

    const variationForField = (field) => {
        const variationsOfField = []

        // make copy of this product variations fields
        const variationFieldsToCompare = { ...product.variation_fields }

        // and delete the field
        delete variationFieldsToCompare[field]

        // compare it with all variations
        variations?.results.forEach((variation) => {
            // get a copy of variation fields for product
            const productVariationFieldsToCompare = {
                ...variation.variation_fields,
            }
            delete productVariationFieldsToCompare[field]

            if (
                isEqual(
                    productVariationFieldsToCompare,
                    variationFieldsToCompare
                )
            ) {
                variationsOfField.push({
                    id: variation.id,
                    variationFields: variation.variation_fields,
                })
            }
        })

        const sizes = ['XXS', 'XS', 'S', 'M', 'L', 'XL', 'XXL', 'XXXL', '3XL']

        // if all variations are of type "size"
        if (
            variationsOfField.every(
                (item) =>
                    item.variationFields[field] &&
                    sizes.includes(
                        `${item.variationFields[field]}`.toUpperCase()
                    )
            )
        ) {
            return variationsOfField.sort((a, b) =>
                sizes.indexOf(a.variationFields[field].toUpperCase()) >
                sizes.indexOf(b.variationFields[field].toUpperCase())
                    ? 1
                    : -1
            )
        }

        // normal sorting
        return variationsOfField.sort((a, b) => {
            const valA = a.variationFields[field]
            const valB = b.variationFields[field]

            // Probeer beide waarden om te zetten naar een getal
            const numA = parseFloat(valA)
            const numB = parseFloat(valB)

            const isNumA = !isNaN(numA)
            const isNumB = !isNaN(numB)

            if (isNumA && isNumB) {
                return numA - numB // Numerieke sortering
            }

            return String(valA).localeCompare(String(valB), undefined, {
                numeric: true,
            })
        })
    }

    return (
        <ProductVariations
            {...props}
            product={product}
            variationForField={variationForField}
            availableIds={availableIds}
            variations={variations.results}
        />
    )
}

type VariationsProps = VariationLinkProps & {
    product: Product
    variations: List<Product>
    availableVariations?: List<ProductLocation>
    noLabels?: boolean
}

const Variations = ({ product, variations, ...props }: VariationsProps) => {
    if (!product) return null
    if (!variations) return null
    if (variations.count === 1) return null

    return (
        <ProductVariationsLoader
            variations={variations}
            {...props}
            product={product}
        />
    )
}

export default Variations
