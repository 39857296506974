import { t } from '@zupr/i18n'
import { getName } from '@zupr/utils/countries'
import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()

const useFood = ({ food }: any) => {
    const specs = []

    if (!food) return specs

    if (food.alcohol_percentage) {
        specs.push({
            label: 'product-page.title.alcohol_percentage',
            value: `${Number(food.alcohol_percentage).toFixed(1)}%`,
        })
    }

    if (food.ingredients) {
        specs.push({
            label: 'product-page.title.ingredients',
            value: food.ingredients.join(', '),
        })
    }

    if (food.allergens) {
        specs.push({
            label: 'product-page.title.allergens',
            value: food.allergens.map((allergen) => t(allergen)).join(','),
        })
    }

    if (food.quantity || (food.weight_unit && food.weight_value)) {
        let val

        const value = parseFloat(Number(food.weight_value).toFixed(10))
        const quantity = (food.quantity && Number(food.quantity)) || 1
        const unit = food.weight_unit || 'g'

        if (food.weight_unit) {
            if (quantity < 2) {
                val = t('%{value} %{unit}', { value, unit })
            } else {
                val = t('%{quantity} x %{value} %{unit}', {
                    quantity,
                    value,
                    unit,
                })
            }
        } else {
            if (quantity) {
                val = t('pack of %{quantity} |||| pack of %{quantity}', {
                    quantity,
                    smart_count: quantity,
                })
            }
        }

        specs.push({
            label: 'product-page.title.contents',
            value: val,
        })
    }

    if (food.country || food.region) {
        specs.push({
            label: 'product-page.title.origin',
            value: `${getName(
                food.country,
                publicRuntimeConfig.NEXT_PUBLIC_LANGUAGE
            )} ${(food.region && ` - ${food.region}`) || ''}`,
        })
    }

    return specs
}

export default useFood
