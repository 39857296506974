import { t } from '@zupr/i18n'
import { getName } from '@zupr/utils/countries'

const useClothing = ({
    designer,
    year,
    collection,
    country,
    style,
    demographic,
}: any) => {
    const info = []

    if (designer) {
        info.push({
            label: 'product-page.title.designer',
            value: designer,
        })
    }

    if (year) {
        info.push({
            label: 'product-page.title.year',
            value: year,
        })
    }

    if (collection) {
        info.push({
            label: 'product-page.title.collection',
            value: collection,
        })
    }

    if (country) {
        info.push({
            label: 'product-page.title.country',
            value: getName(country),
        })
    }

    if (demographic) {
        info.push({
            label: 'product-page.title.demographic',
            value: demographic.map((choice) => t(choice)).join(', '),
        })
    }

    if (style) {
        info.push({
            label: 'product-page.title.style',
            value: style,
        })
    }

    return info
}

export default useClothing
