export const clothingMaterialGroups = [
    'Main Material',
    'Contrast Fabric 1',
    'Contrast Fabric 2',
    'Outer shell',
    'Lining',
    'Insulation',
    'Trimming',
    'No group',
]

export const footwearMaterialGroups = ['Upper', 'Lining and sock', 'Sole']

export interface Color {
    value: string
    background: string
}

export const colors: Color[] = [
    {
        value: 'white',
        background: '#FFFFFF',
    },
    {
        value: 'black',
        background: '#000000',
    },
    {
        value: 'grey',
        background: '#C0C0C0',
    },
    {
        value: 'cream',
        background: '#FFEED6',
    },
    {
        value: 'brown',
        background: '#9A6C53',
    },
    {
        value: 'purple',
        background: '#8E44AD',
    },
    {
        value: 'dark-blue',
        background: '#2586D8',
    },
    {
        value: 'light-blue',
        background: '#6DB4F0',
    },
    {
        value: 'green',
        background: '#43AD4B',
    },
    {
        value: 'yellow',
        background: '#F1C40F',
    },
    {
        value: 'orange',
        background: '#FF901F',
    },
    {
        value: 'red',
        background: '#E74C3C',
    },
    {
        value: 'pink',
        background: '#F079C7',
    },
    {
        value: 'multicolor',
        background: `conic-gradient(#f1c40f 0% 12.5%,#43ad4b 12.5% 25%,#6db4f0 25% 37.5%,#2586d8 37.5% 50%,#8e44ad 50% 62.5%,#f079c7 62.5% 75%,#ff901f 75% 87.5%, #e74c3c 87.5% 100%);`,
    },
]
